<template>
    <div>
        <div class="header">
            <div class="header-l">
                <img v-if="showMenu" src="@/assets/fenlei/fenlei.png" alt="" @click="isShowMenu(0)">
                <img v-else src="@/assets/fenlei/fenlei.png" alt="" @click="isShowMenu(1)">
                <img src="@/assets/slices/ic_shouye1.png" alt="" @click="goIndex">
            </div>
            <div class="header-r">
                <input placeholder="keywords" v-model="searchText" type="text">
                <img src="@/assets/slices/ic_sousuo.png" alt="" @click="searchGame">
            </div>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'

    export default {
        data() {
            return {
                showMenu: false,
                searchText: ''
            }
        },
        mounted() {
            eventBus.$on('showfenlei',i=>{
                this.showMenu = i
            })
        },
        methods: {
            isShowMenu(i){
                this.showMenu = i;
                eventBus.$emit('showmenu',i)
            },
            goIndex(){
                if(this.$route.path!='/') this.$router.push('/')
                else this.$router.go(0)
            },
            searchGame(){
                // this.showMenu = false;
                // eventBus.$emit('showfenlei',false)
                if(this.$route.path != '/selectGame'){
                    this.$router.push({
                        path: '/selectGame',
                        query: {
                            id: 'search',
                            key: this.searchText
                        }
                    })
                }else{
                    eventBus.$emit('searchgame',{
                        id:'search',
                        key:this.searchText
                    });
                }
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .header{
    width: 1200px;
  }
}
@media (max-width: 768px) {
  .header{
    width: 100%;
  }
}
.header{
    z-index: 999;
    height: 50px;
    background: #504BA9;
    border-radius: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    .header-l{
        display: flex;
        align-items: center;
        margin-left: 8px;
        img{
            width: 35px;
            height: 35px;
            margin-left: 8px;
        }
    }
    .header-r{
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        width: 231px;
        height: 38px;
        margin-right: 11px;
        background: #DEDCFF;
        border-radius: 10px;
        input{
            margin-left: 12px;
            background-color: #DEDCFF;
            border: none;
            border-radius: 10px;
            outline: none;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #504BA9;
            // line-height: 22px;
            text-align: left;
            font-style: normal;
        }
        img{
            width: 17px;
            height: 17px;
        }
    }
}
</style>